import styled from 'styled-components';
import { theme } from '../../theme'
import {Col, Container, Row} from "react-bootstrap";

export const Name = styled(Col)`
    font-size: ${theme.typography.fontSizeTitle}
`

export const Subtitle = styled(Col)`
    font-family: ${theme.typography.thinFontFamily}
    font-size: ${theme.typography.fontSize}
`

export const RowContainer = styled(Row)`
    display: flex;
    align-items: center;
    width: fit-content;
    height: 100%;
`

export const WorkContainer = styled(Container)`
    margin: 2.5rem;
    width: auto;
    
    @media screen and (max-width: ${ theme.sizes.md }px) {
       margin: 0px;
    }
`

export const Works = styled.div`
   background: ${theme.colors.bgGray};
   display: flex;
   margin-left: 5em;
    padding-right: 8em;
   
   @media screen and (max-width: ${ theme.sizes.md }px) {
       margin: 0px;
       display: block;
        padding-right: 0;
    }
`


