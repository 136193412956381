import React, {ReactElement, useRef} from 'react';
import * as Styles from './style';
import WorksItem from '../../assets/json/works'
import Work from '../../components/Work/Work';
import Banner from '../../components/Banner/Banner';
import Title from '../../components/Title/Title';
import {isMobile} from "../../utils/useScreenSize";
import Slider from "../../components/Slider/Slider";
import {AnimationOnScroll} from "react-animation-on-scroll";
import { useRefSection } from '../../utils/useRefSections';

interface WorksProps {
	useRefs: ReturnType<typeof useRefSection>
}

const Works = ({useRefs}: WorksProps): ReactElement => {

    const isMobileScreen = isMobile()
    const itemsRef = useRef<object>({})
    let refTitle = useRef<HTMLElement>(null);

    return (
        <Styles.Works id='work' ref={useRefs.refWork}>
            {
                isMobileScreen && <Banner velocity={70}/>
            }
            <Title reference={refTitle} limit={810} name='works' isMobileScreen={isMobileScreen}/>
            <Styles.WorkContainer >
                {
                    isMobileScreen ?
                        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1} animatePreScroll={false} animateOnce={true}>
                            <Slider
                                items={WorksItem.map((item, index) => <Work index={index} ref={itemsRef} key={index}
                                                                            project={item}/>)}
                            />
                        </AnimationOnScroll>
                        :
                        <Styles.RowContainer>
                            {WorksItem.map((item, index) => <Work index={index} key={index} project={item}/>)}
                        </Styles.RowContainer>
                }
            </Styles.WorkContainer>
        </Styles.Works>
    );
};

export default Works;
