import React, {ReactElement, forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import * as Styles from './style';
import {isMobile} from "../../utils/useScreenSize";
import Link from '../Link/Link'
import {BsChevronDown, BsChevronUp} from "react-icons/bs";
import {useTranslation} from "react-i18next";

interface WorkProps {
    project: {
        title: string;
        description: string;
        image?: string;
        video?: string;
        label: string[];
    },
    index: number
}

const Work = forwardRef(({project, index}: WorkProps, ref): ReactElement => {

    const isMobileScreen = isMobile()
    const { t } = useTranslation();
    const description = t(project.description)
    const space = description.indexOf('.', 190) + 1
    const [showMore, setShowMore] = useState<number>(space)
    const [showButton, setShowButton] = useState<boolean>(true)

    useImperativeHandle(ref, () => ({

        handleClose() {
            setShowMore(space)
        }

    }));

    useEffect(() => {
       setShowMore(space)
    }, [description])

    useEffect(() => {
        if (!isMobileScreen) {
            setShowMore(-1)
            setShowButton(false)
        } else {
            setShowButton(!!!((description.length - 1) <= space ))
        }
    }, [])

    const handleShow = () => {
        setShowMore(showMore === -1 ? space : -1)
    }

    return (
        <Styles.WorkContainer style={{borderLeft: index === 0 ? '0' : '1px solid'}}
                              className={`card-container ${showMore === space ? '' : 'isOpen'}`}
                              initial="offscreen"
                              whileInView="onscreen"
                              viewport={{ once: true, amount: 0.8 }}
        >
            <h2>{project.title}</h2>
            <h5>{project.label.map((item: string, index: number) => project.label[index + 1] ? `${item}, ` : item)}</h5>
            <div className={`description ${showMore === space ? '' : 'open'}`}>{((description.length - 1) <= space  || !isMobileScreen || showMore === -1) ? description : description.slice(0, showMore)}
                {showButton && <Link
                    icon={showMore === space ? <BsChevronDown/> : <BsChevronUp/>}
                    text={showMore === space ? 'View more' : 'View less'}
                    onClick={handleShow}
                />}
            </div>
            <Styles.Image className={`${isMobileScreen ? showMore === space ? '' : 'isOpen' : ''}`}>
                {
                    project.video ?
                        // @ts-ignore
                        <video fetchpriority="high" controls preload="auto">
                            <source src={project.video} type="video/mp4"/>
                        </video>
                        :
                        // @ts-ignore
                        <img fetchpriority="high" src={project.image} alt={project.title}/>
                }
            </Styles.Image>
        </Styles.WorkContainer>
    );
});

export default Work;
