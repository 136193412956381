interface dataItems {
    title: string;
    description: string;
    image?: string;
    video?: string;
    label: string[];
}


const data: Array<dataItems> = [
    {
        "title": "Virtual card",
        "description": "virtual_description",
        "image": require("./../img/projects/virtual-card.png"),
        "label": [
            "Design",
            "Development",
            "Website",
            "SSO"
        ]
    },
    {
        "title": "Kiui app",
        "description": "kiui_description",
        "image": require("./../img/projects/kiui-app.png"),
        "label": [
            "Design",
            "Development",
            "Website"
        ]
    },
    {
        "title": "You Work",
        "description": "you_work_description",
        "image": require("./../img/projects/you-work.png"),
        "label": [
            "Design",
            "Development",
            "Website"
        ]
    },
    {
        "title": "Casa Pichón",
        "description": "jamones_description",
        "image": require("./../img/projects/jamones-casa-pichon.png"),
        "label": [
            "Design",
            "Development",
            "E-commerce",
            "Payment system"
        ]
    },
    {
        "title": "Feliz Niavidad",
        "description": "feliz_niavidad_description",
        "video": require("./../img/projects/feliz-niavidad.mov"),
        "label": [
            "Design",
            "Development",
            "Artificial Intelligent"
        ]
    },
    {
        "title": "Covid Game",
        "description": "covid_game_description",
        "video": require("./../img/projects/covid-the-game.mov"),
        "label": [
            "Design",
            "Development"
        ]
    }
]

export default data;