import styled from 'styled-components';

import "swiper/css";
import "swiper/css/pagination";
import {theme} from "../../theme";

export const SliderWrapper = styled.div`
    display: block;

    & .swiper-pagination {
        position: relative;
        padding-bottom: 20px;
    }
        
    & .swiper-pagination-bullet-active {
        background: ${theme.colors.primary} !important;
    }

    & .swiper-pagination-bullet-active {
        background: gray;
    }


   
`;

export const Wrapper = styled.div`
    display: none;
    
    @media screen and (min-width: ${theme.sizes.md}px){
        display: 'flex' ;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        
        & > div {
            max-width: 380px;
        }
    }
`;



