import styled, {keyframes} from 'styled-components';
import { theme } from '../../theme'
import {Col, Container, Row} from "react-bootstrap";
import CursorWhite from '../../assets/img/cursor-white.svg'

export const Name = styled(Col)`
    font-size: ${theme.typography.fontSizeTitle}
`

export const Subtitle = styled(Col)`
    font-family: ${theme.typography.thinFontFamily}
    font-size: ${theme.typography.fontSize}
`

export const RowContainer = styled(Row)`
    padding: 0px 40px 40px;
    width: 100%;

    .col-text {
      text-transform: uppercase;
      font-size: 24px;
      line-height: 1.3;
    }
    
     @media screen and (max-width: ${ theme.sizes.md }px) {
          width: 100%;
          padding: 20px;


          .col-text {
            text-transform: none;
            font-size: 20px;
            line-height: 1.2;
          }
     }
     
      @media screen and (min-width: ${ theme.sizes.lg }px) {
        width: 71%;

          .col-text {
            font-size: 24px;
          }
     }
     
     
     
`

export const DescriptionLower = styled.div`
    position: absolute;
    margin-top: 20px;
    width: 50%;
    bottom: 40px;

    & > div {
      width: 50%;
      color: ${theme.colors.gray};

      & a {
        color: ${theme.colors.gray};
        margin-left: 5px;
      }
    }
    
     @media screen and (max-width: ${ theme.sizes.md }px) {
          width: 100%;
          padding: 20px 0;
          position: initial;


          & > div {
            width: 100%;
          }
     }
     
     @media screen and (min-width: ${ theme.sizes.lg }px) {
         & > div {
            width: 43%;
         }
     }
     
     
     
`

export const ContactButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const ContactButton = styled.div`
    background-color: ${theme.colors.primaryLight};
    padding: 20px;
    color: white;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    float: right;
    margin-right: -50px;
    margin-bottom: 100px;
    z-index: 10;
    cursor: url(${CursorWhite}), auto;

     @media screen and (max-width: ${ theme.sizes.md }px) {
        margin-right: 0px;
        position: initial;
        margin-bottom: 20px;
     }
     
     &:hover {
        animation-name: ${rotate};
        animation-duration: 4000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
     }
`
export const RowImage = styled(Row)`

    & .col {
      position: absolute;
      display: flex;
      bottom: 0;
      right: 0;
      justify-content: flex-end;
      align-items: center;
      text-align: end;
    }
    
    & img {
        width: 22em;
        height: auto;

    }
    
     @media screen and (min-width: ${ theme.sizes.lg }px) {
          & img {
            width: 22em;
        }
     }
     
     @media screen and (max-width: ${ theme.sizes.md }px) {
          padding: 20px;
          
          & img {
            width: 100%;
            height: auto;
            position: initial;
          }

          & .col {
            position: initial;
            flex-direction: column;
            align-items: flex-end;
          }
     }
     
     
`

export const AboutContainer = styled(Container)`
    padding: 70px;
    min-width: 100vw;
    height: 97vh;
    position: relative;
    margin-right: 8em;
    
    @media screen and (max-width: ${ theme.sizes.md }px) {
          padding: 0;
          height: 100%;
          min-width: 100%;
     }
`


