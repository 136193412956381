import { useRef } from "react";

export const useRefSection = () => {
    let refContact = useRef(null);
    let refWork = useRef(null);
    let refAbout = useRef(null);

    const onButtonClick = (ref: any) => {
        if (!ref) return
        
        window.scrollTo({ behavior: "smooth", top: ref.current.offsetTop });
    };


    return {
        refContact,
        refWork,
        refAbout,
        onButtonClick
    }
}