import styled from 'styled-components';
import { theme } from '../../theme'

export const TitleContainer = styled.div`
    width: 10em;
    margin-left: -6em;

    & > div {
        margin: 0;
        -webkit-transform: translateY(50vh) rotate(-90deg);
        transform: translateY(50vh) rotate(-90deg);
        display: flex;
        flex-direction: row;
        align-items: center;
        
        h1 {
            font-size: 13em;
            padding: 0 20px;
            color: ${theme.colors.primaryLight};
            font-family: 'Neue-light',sans-serif;
        }
        
         & div {
               border-bottom: 1px solid ${theme.colors.primaryLight};
               width: 350px;
               padding: 0 70px;
               margin: 0 40px;
               -webkit-transform: translateY(-50%);
               transform: translateY(-50%);
               height: 1px;
               margin-top: 50px;
         }
       
    }
`

export const MobileTitleContainer = styled.div`
    border-top: 1px solid ${theme.colors.primaryBorder};
    border-bottom: 1px solid ${theme.colors.primaryBorder};
    margin: 2em 20px 0;
    overflow: hidden;
    color: ${theme.colors.blackLight};
    
    div {
        margin: -10px 2px;
       
        h1 {
            font-size: 4.6em;
            margin: 0;
        }
       
    }
`