import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { theme } from '../../theme'
import { motion } from "framer-motion";

export const WorkContainer = styled(motion.div)`
    border-left-color: ${theme.colors.primaryBorder} !important;
    padding: 0 2.5rem;
    position: relative;
    height: 100%;
    width: 37rem;
    letter-spacing: .5px;

    h5 {
        margin-top: 5px;
        color: #949494;
    }

    h2 {
        margin-bottom: 5px;
    }
    
    & .description {
        margin: 20px 0;
      color: #9a9a9a;
    }
    
     @media screen and (max-width: ${ theme.sizes.md }px) {
       position: initial;
       padding: 20px;
       width: 100%;
       border-left: none !important;
       height: 37em;
       
       &.isOpen {
        height: fit-content;
       }
       
       & .description {
         font-size: 18px;
       }
    }
`

export const Image = styled(Col)`
    position: absolute;
    bottom: 0;
    
    &.isOpen {
        position: initial;
    }

    & img {
        width: 28em;
        height: 15em;;
    }
    
     & video {
        width: 28em;
        height: 15em;
    }
    
    @media screen and (max-width: ${ theme.sizes.md }px) {
        bottom: 20px;
       
        & img {
            width: 100%;
            height: auto;
        }
        & video {
            width: 100%;
            height: auto;
        }
        
        
    }
`