import {useEffect, useState} from "react";
import { theme } from '../theme'

const UseScreenSize = (toSize : number, fromSize: number = 0) => {

    const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

    const setDimension = () => {
        setScreenSize(window.outerWidth <= window.innerWidth ? window.outerWidth : window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return () => window.removeEventListener('resize', setDimension)
    }, [screenSize]);

    return screenSize > fromSize && screenSize < toSize ;
}

export const isMobile =  () => UseScreenSize(theme.sizes.md)
