import React from "react";
import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import ESTranslation from '../assets/json/es.json'
import ENTranslation from '../assets/json/en.json'

const language = localStorage.getItem('language')

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: ENTranslation
            },
            es: {
                translation: ESTranslation
            }
        },
        lng: language ? language : "es", // if you're using a language detector, do not define the lng option
        fallbackLng: language ? language : "es",
        returnNull: false,
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });