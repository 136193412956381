import styled from 'styled-components';
import { theme } from '../../theme'

export const BannerContainer = styled.div`
    font-size: 10px;
    width: 100%;
    align-items: center;
    background: ${theme.colors.bgBanner};
    color: ${theme.colors.colorBanner};
    margin: 20px 0;
    overflow: hidden;

    div {
        margin: 0;
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: max-content;
        
        h1 {
            letter-spacing: 1px;
            font-weight: 600;
        }
       
    }
`
