import React, {ReactElement, useEffect, useState} from 'react';
import * as Styles from './style';
import {FaRegHandPeace, FaRegLaughWink} from "react-icons/fa";

interface BannerProps {
    velocity?: number
}

const Banner = ({ velocity = 60 } : BannerProps): ReactElement => {

    const [move, setMove] = useState<number>(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setMove(move => move >= -550 ? move - 2 : 0)
        }, velocity);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Styles.BannerContainer>
                <div style={{translate: `${move}px 0`}}>
                    <h1>DESIGN + DEVELOPMENT</h1>
                    <h1><FaRegHandPeace/></h1>
                    <h1>AITOR GUERRERO</h1>
                    <h1><FaRegLaughWink/></h1>
                    <h1>DESIGN + DEVELOPMENT</h1>
                    <h1><FaRegHandPeace/></h1>
                    <h1>AITOR GUERRERO</h1>
                    <h1><FaRegLaughWink/></h1>
                </div>
            </Styles.BannerContainer>
        </>

    );
};

export default Banner;
