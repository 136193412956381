import React, { ReactElement } from 'react';
import * as Styles from './style';
import {Col} from "react-bootstrap";
import AitorImage from './../../assets/img/aitor.jpg'
import {Trans} from "react-i18next";
import {motion} from "framer-motion";
import CV_AITOR from './../../assets/pdf/CV_AITOR.pdf'
import {useTranslation} from "react-i18next";
import { useRefSection } from '../../utils/useRefSections';

interface AboutProps {
	useRefs: ReturnType<typeof useRefSection>
}

const About = ({useRefs}: AboutProps): ReactElement => {
	const { t } = useTranslation();

	return (
		<Styles.AboutContainer id='about' ref={useRefs.refAbout}>
			{/* <Styles.RowContainer >
				<Styles.Subtitle>Full Stack Developer</Styles.Subtitle>
				<Styles.Name>Aitor Guerrero</Styles.Name>
			</Styles.RowContainer> */}
			<Styles.RowContainer >
				<Col className='col-text'>
					<motion.div initial={{opacity: 0}}
								exit={{opacity: 1}}
								animate={{opacity: 1}}
								transition={{duration: 0.6, delay: 4, ease: "easeInOut"}}>
						<Trans i18nKey="about_description">
						</Trans>
					</motion.div>
				</Col>
				<Styles.DescriptionLower>
					<motion.div initial={{opacity: 0}}
								exit={{opacity: 1}}
								animate={{opacity: 1}}
								transition={{duration: 0.6, delay: 4, ease: "easeInOut"}}>
						<Trans i18nKey="about_description_lower">
						</Trans>
						<a rel="noreferrer" href={CV_AITOR} download='CV_AITOR_GUERRERO.pdf' target='_blank'> {t('about_description_download_link')}</a>
					</motion.div>
				</Styles.DescriptionLower>
				{/* <Styles.ContactButtonContainer>
					<Styles.ContactButton onClick={() => window.location.href = `#contact`}>
						<Trans i18nKey="about_contact_button">
						</Trans>
					</Styles.ContactButton>
				</Styles.ContactButtonContainer> */}
			</Styles.RowContainer>
			<Styles.RowImage >
				<Col>
				<Styles.ContactButtonContainer>
					<Styles.ContactButton className='button' onClick={() => window.location.href = `#contact`}>
						<Trans i18nKey="about_contact_button">
						</Trans>
					</Styles.ContactButton>
				</Styles.ContactButtonContainer>
					<motion.div initial={{opacity: 0, width: '100%'}}
								exit={{opacity: 1}}
								animate={{opacity: 1}}
								transition={{duration: 2, delay: 4, ease: "easeInOut"}}>
						<img src={AitorImage} alt='aitor' />
					</motion.div>
				</Col>
			</Styles.RowImage>
		</Styles.AboutContainer>
	);
};

export default About;
