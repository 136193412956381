import React, {useEffect, useState} from 'react';
import './App.scss';
import Header from './components/Header/Header';
import {isMobile} from "./utils/useScreenSize";
import About from "./pages/About/About";
import Works from './pages/Works/Works';
import Contact from "./pages/Contact/Contact";
import UseHorizontalScroll from "./utils/useHorizontalScroll";
import './utils/i18n'
import {useTranslation} from "react-i18next";
import Presentation from "./components/Presentation/Presentation";
import "animate.css/animate.min.css";
import { useRefSection } from './utils/useRefSections';

const MENU = {
    ABOUT: 'about',
    CONTACT: 'contact',
    WORK: 'work'
}

export const enum Languages {
    ES = 'es',
    EN = 'en'
}

function App() {

    const currentLanguage = localStorage.getItem('language')


    const isMobileScreen = isMobile()
    const [isActive, setIsActive] = useState('')
    const [language, setLanguage] = useState<Languages>(currentLanguage && currentLanguage === Languages.EN ? Languages.EN : Languages.ES)
    const [showPresentation, setShowPresentation] = useState<boolean>(true)
    const {i18n} = useTranslation();
    const useRefs = useRefSection();

    useEffect(() => {
        window.scroll(0, 0)
        window.history.replaceState(null, "Aitor Guerrero", "/about")
        setIsActive(MENU.ABOUT)
        const showTimer = setTimeout(() => setShowPresentation(false), 4000)

        return () => clearTimeout(showTimer)
    }, [])

    useEffect(() => {
        const onscroll = (e: any) => {
            if (e.deltaY === 0) return;
            const aboutContainer = document.getElementById('about')?.getBoundingClientRect()
            const workContainer = document.getElementById('work')?.getBoundingClientRect()
            const contactContainer = document.getElementById('contact')?.getBoundingClientRect()


            if (aboutContainer && ((aboutContainer.top > -100 && isMobileScreen) || aboutContainer.left > -500 && !isMobileScreen)) {
                window.history.replaceState(null, "Aitor Guerrero", "/about")
                setIsActive(MENU.ABOUT)
            } else if (workContainer && ((workContainer.top < 150 && workContainer.top > -200 && isMobileScreen) || (workContainer.left < 500 && workContainer.left > -1500 && !isMobileScreen))) {
                window.history.replaceState(null, "Aitor Guerrero", "/work")
                setIsActive(MENU.WORK)
            } else if (contactContainer && ((contactContainer.top < 300 && isMobileScreen) || (contactContainer.left < 700 && !isMobileScreen))) {
                window.history.replaceState(null, "Aitor Guerrero", "/contact")
                setIsActive(MENU.CONTACT)
            }
        };
        window.addEventListener("scroll", onscroll);
        return () => window.removeEventListener("scroll", onscroll);
    }, []);

    const changeLanguage = (language: Languages): void => {
        i18n.changeLanguage(language)
        setLanguage(language)
        localStorage.setItem('language', language)
    }

    return (
        <>
            <Presentation show={showPresentation}/>
            <UseHorizontalScroll show={!showPresentation}>
                <div className={`App ${showPresentation ? 'hidden' : ''}`} id='app'>
                    <Header
                        isActive={isActive}
                        setIsActive={setIsActive}
                        language={language}
                        changeLanguage={changeLanguage}
                        useRefs={useRefs}
                    />
                    <div className='app-container'>
                        <About useRefs={useRefs}/>
                        <Works useRefs={useRefs}/>
                        <Contact useRefs={useRefs}/>
                    </div>
                </div>
            </UseHorizontalScroll>
        </>
    );
}

export default App;
