import React, {useEffect} from "react";

type Props = {
    children: JSX.Element,
    show: boolean
};

const UseHorizontalScroll: React.FC<Props> = ({children, show}) => {

    useEffect(() => {
        if (!show) return

        const onWheel = (e: any) => {
            if (e.deltaY === 0) return;

            window.scrollTo({
                left: window.scrollX + (e.deltaY * 0.78),
                behavior: "auto"
            });

            //behavior: "smooth"
        };
        window.addEventListener("wheel", onWheel);
        return () => window.removeEventListener("wheel", onWheel);
    }, [show]);

    return (<React.Fragment>
        {children}
    </React.Fragment>)
}

export default UseHorizontalScroll
