import React, {ReactElement, useEffect, useState} from 'react';
import * as Styles from './style';
import {isMobile} from "../../utils/useScreenSize";
import {Languages} from "../../App";
import {useTranslation} from "react-i18next";
import { useRefSection } from '../../utils/useRefSections';

const MENU = {
    ABOUT: 'about',
    CONTACT: 'contact',
    WORK: 'work'
}

interface HeaderProps {
    isActive: string,
    setIsActive: any,
    language: string,
    changeLanguage: any,
    useRefs: ReturnType<typeof useRefSection>
}

const Header = ({isActive, setIsActive, language, changeLanguage, useRefs}: HeaderProps): ReactElement => {
    const [position, setPosition] = useState(window.scrollX)
    const [visible, setVisible] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [firstTime, setFirstTime] = useState<boolean | null>(null)

    const isMobileScreen = isMobile()
    const { t } = useTranslation();

    useEffect(() => {
        if (isMobileScreen) return

        const handleScroll = () => {
            let moving = window.scrollX
            if (moving < 60) return

            setVisible(position > moving);
            setPosition(moving)
        };

        window.addEventListener("scroll", handleScroll);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
        })
    })

    const handleShow = (): void => {
        setFirstTime(true)
        setIsOpen(open => !open)
    }

    const handleClick = (name: string, close?: boolean) : void => {
        setIsActive(name)
        if (close) {
            setIsOpen(false)
        }
        
        let ref = null;

        switch (name) {
            case MENU.ABOUT:
                ref = useRefs.refAbout;
                useRefs.onButtonClick(useRefs.refAbout)
                break;
            case MENU.CONTACT:
                ref = useRefs.refContact;
                break;
            case MENU.WORK:
                ref = useRefs.refWork;
                break;
        }

        useRefs.onButtonClick(ref)
       
    }

    const onClickToHome = () => {
        window.scroll(0, 0)
    }

    return (
        <>
            {isMobileScreen ?
                <Styles.HeaderMobile className={`${visible ? "visible" : "hidden"} ${firstTime ? isOpen ? "open" : "close" : ''}`}>
                    <nav className="nav_menu">
                        <button onClick={onClickToHome} className='header_logo'>AITOR GUERRERO</button>
                        <button className={`button ${isOpen ? "open" : "close"}`} onClick={handleShow}>
                            <div></div>
                            <div></div>
                        </button>
                    </nav>
                    <ul className={`header_menu ${firstTime ? isOpen ? "open" : "close" : ''}`}>
                        <li onClick={() => handleClick(MENU.ABOUT, true)} className={`header_menu_item ${isActive === MENU.ABOUT ? 'active' : ''}`}><a href='#about'>{t("header_about")}</a></li>
                        <li onClick={() => handleClick(MENU.CONTACT, true)} className={`header_menu_item ${isActive === MENU.CONTACT ? 'active' : ''}`}><a href='#contact'>{t("header_contact")}</a></li>
                        <li onClick={() => handleClick(MENU.WORK, true)} className={`header_menu_item ${isActive === MENU.WORK ? 'active' : ''}`}><a href='#work'>{t("header_works")}</a></li>
                        <li className={`header_menu_item_language ${!isOpen ? "disabled" : "show" }`}>
                            <button className={`${language === Languages.EN ? 'active' : ''}`} onClick={() => {changeLanguage(Languages.EN); setIsOpen(false)}}>EN</button>
                            <button className={`${language === Languages.ES ? 'active' : ''}`} onClick={() => {changeLanguage(Languages.ES); setIsOpen(false)}}>ES</button>
                        </li>
                    </ul>
                </Styles.HeaderMobile>
                :
                <Styles.Header className={visible ? "visible" : "hidden"}>
                    <ul className="header_menu">
                        <li onClick={() => handleClick(MENU.ABOUT)} className={`header_menu_item ${isActive === MENU.ABOUT ? 'active' : ''}`}><a href='#about'>{t("header_about")}</a></li>
                        <li onClick={() => handleClick(MENU.CONTACT)} className={`header_menu_item ${isActive === MENU.CONTACT ? 'active' : ''}`}><a href='#contact'>{t("header_contact")}</a></li>
                        <li onClick={() => handleClick(MENU.WORK)} className={`header_menu_item ${isActive === MENU.WORK ? 'active' : ''}`}><a href='#work'>{t("header_works")}</a></li>
                        <li className={`header_menu_item`}>
                            <button className={`${language === Languages.EN ? 'active' : ''}`} onClick={() => changeLanguage(Languages.EN)}>EN</button>
                            <button className={`${language === Languages.ES ? 'active' : ''}`} onClick={() => changeLanguage(Languages.ES)}>ES</button>
                        </li>
                    </ul>
                    <button onClick={onClickToHome} className='header_logo'>AITOR GUERRERO</button>
                </Styles.Header>
            }
        </>
    );
};

export default Header;
