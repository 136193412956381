import React, {ReactElement, useRef, useState} from 'react';
import * as Styles from './style';
import Title from '../../components/Title/Title';
import {Col} from "react-bootstrap";
import ArrowRight from "../../assets/img/arrow-right.png";
import emailjs from '@emailjs/browser';
import {CircularProgress} from "@mui/material";
import {isMobile} from "../../utils/useScreenSize";
import Banner from '../../components/Banner/Banner';
import {Trans, useTranslation} from "react-i18next";
import {AnimationOnScroll} from "react-animation-on-scroll";
import {FaLinkedinIn  } from "react-icons/fa";
import {HiMail, HiPhone} from "react-icons/hi";
import { useRefSection } from '../../utils/useRefSections';

interface ContactProps {
	useRefs: ReturnType<typeof useRefSection>
}

const Contact = ({useRefs}: ContactProps): ReactElement => {

    const isMobileScreen = isMobile()
    const {t} = useTranslation();
    const [values, setValues] = useState({
        name: '',
        email: '',
        message: '',
        isLoading: false
    })
    const [onBlur, setOnBlur] = useState({
        name: false,
        email: false,
        message: false
    })

    const [error, setError] = useState({
        name: false,
        email: false,
        message: false
    })
    const form = useRef<HTMLFormElement>(null);
    let refTitle = useRef<HTMLElement>(null);

    const handleChange = (e: any): void => {
        const {name, value} = e.target;

        setValues({...values, [name]: value})
        setError({
            name: false,
            email: false,
            message: false
        })
        setOnBlur({
            name: false,
            email: false,
            message: false
        })
    }

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleBlur = (e: any): void => {
        const {name} = e.target;

        setOnBlur({...onBlur, [name]: true})

        if (e.target.value !== '') {
            e.target.nextElementSibling.classList.add("filled");
        } else {
            e.target.nextElementSibling.classList.remove("filled");
        }
    }

    const handleSubmit = (e: any): void => {
        e.preventDefault();
        if (!form.current) return

        let errorEmail: boolean = false, errorContent: boolean = false, errorName: boolean = false;

        if (!validateEmail(values.email)) {
            errorEmail = true;
        }
        if (values.name === '') {
            errorName = true;
        }
        if (values.message === '') {
            errorContent = true;
        }

        if ([errorEmail, errorContent, errorName].some((item) => item)) {
            return setError({
                name: errorName,
                email: errorEmail,
                message: errorContent
            })
        }

        setValues({...values, isLoading: true})
        // @ts-ignore
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_EMAIL, form.current, process.env.REACT_APP_KEY_EMAIL)
            .then((result) => {
                setValues({
                    ...values,
                    name: '',
                    email: '',
                    message: '',
                    isLoading: false
                })
            }, (error) => {
                setError({
                    name: true,
                    email: true,
                    message: true
                })
            });

    }

    const date = new Date()
    
    return (
        <Styles.Contact id='contact' ref={useRefs.refContact}>
            {
                isMobileScreen && <Banner />
            }
            <Title reference={refTitle} limit={950} name='contact' isMobileScreen={isMobileScreen}/>
            <Styles.ContactContainer>
                <AnimationOnScroll animateIn="animate__fadeInUp" duration={1} animatePreScroll={false} animateOnce={true}>
                    <Styles.RowContainer>
                        <Col>
                            <Trans i18nKey="contact_description">
                            </Trans>
                        </Col>
                    </Styles.RowContainer>
                    <Styles.RowInput>
                        <form ref={form} onSubmit={handleSubmit}>
                            <Col sm={8} className="input-container">
                                <input id='name' onBlur={handleBlur} onChange={handleChange} value={values.name}
                                       name='name'
                                       className={`input-text ${error.name ? 'input-error' : ''}`} type='text'/>
                                <label className={`label ${values.name ? 'filled' : ''}`} htmlFor="name">{t('input_name')}</label>
                            </Col>
                            <Col sm={8} className="input-container">
                                <input id='email' onBlur={handleBlur} onChange={handleChange} value={values.email}
                                       name='email'
                                       className={`input-text ${error.email ? 'input-error' : ''}`} type='text'/>
                                <label className={`label ${values.email ? 'filled' : ''}`} htmlFor="email">Email</label>
                            </Col>
                            <Col sm={8} className="input-container">
                            <textarea id='message' onBlur={handleBlur} onChange={handleChange} value={values.message}
                                      name='message' rows={5}
                                      className={`input-text text-area ${error.message ? 'input-error' : ''}`}/>
                                <label className="label" htmlFor="message">{t('input_message')}</label>
                            </Col>
                            <Col sm={8}>
                                <button type='submit' className='input-text button-send'>
                                    {values.isLoading ?
                                        <CircularProgress sx={{width: '17px !important', height: '17px !important'}}
                                                          color="secondary"/> :
                                        <img src={ArrowRight} alt='send'/>
                                    }
                                </button>
                            </Col>
                        </form>
                    </Styles.RowInput>
                </AnimationOnScroll>
                <Styles.IconContact>
                    <div className='text'>{t('contact_footer')}</div>
                    <Styles.IconRow>
                        <a rel="noreferrer" href='https://www.linkedin.com/in/aitor-guerrero/' target='_blank'><FaLinkedinIn size={28} /></a>
                        <a rel="noreferrer" href='mailto:aitorguerrero9@gmail.com' target='_blank'><HiMail size={30} /></a>
                        <a rel="noreferrer" href='tel:678736571' target='_blank'><HiPhone size={28} /></a>
                    </Styles.IconRow>
                    <div className='name'>@{date.getFullYear()} Aitor Guerrero</div>
                </Styles.IconContact>
            </Styles.ContactContainer>
        </Styles.Contact>
    );
};

export default Contact;
