import React, {ReactElement, useEffect, useRef, useState} from 'react';
import * as Styles from './style';
import {AnimationOnScroll} from "react-animation-on-scroll";

interface TitleProps {
    name: string,
    isMobileScreen: boolean,
    limit: number,
    reference: any
}
const SPEED = 0.1;

const Title = ({name, isMobileScreen, limit, reference}: TitleProps): ReactElement => {

    const [move, setMove] = useState(0)

    useEffect(() => {
        if (isMobileScreen) return;

        const handleScroll = (e: any) => {
            const height = window.innerHeight - reference.current.clientHeight;
            const width = window.innerWidth - reference.current.clientWidth;

            const move = ((window.scrollX / width) * height) * 0.23
            /*if (move >= 0) {
                setMove((oldMove) => oldMove + move)
            }*/
        }

        const titleInterval = setInterval(() => {
            setMove((move) => move > limit ? 0  : move + 3)
        }, 50);

        window.addEventListener("scroll", handleScroll);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
            clearInterval(titleInterval);
        })
    }, [isMobileScreen]);

    return (
        <>
            {isMobileScreen ?
                <Styles.MobileTitleContainer>
                    <AnimationOnScroll animateIn="animate__bounceInRight" duration={2} animatePreScroll={false}
                                       animateOnce={true}>
                        <div>
                            <h1>{name}</h1>
                        </div>
                    </AnimationOnScroll>
                </Styles.MobileTitleContainer>
                :
                <Styles.TitleContainer>
                    <div ref={reference} style={{translate: `0 ${move}px`}}>
                        <h1>{name}</h1>
                        <div></div>
                        <h1>{name}</h1>
                        <div></div>
                        <h1>{name}</h1>
                    </div>
                </Styles.TitleContainer>
            }
        </>

    );
};

export default Title;
