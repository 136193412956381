import styled, {keyframes} from 'styled-components';
import { theme } from '../../theme'
import {motion} from "framer-motion";


const animate = keyframes`
 
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
`;

export const PresentationContainer = styled(motion.div)`
    font-size: 10px;
    width: 100%;
    align-items: center;
    background: ${theme.colors.black};
    color: ${theme.colors.colorBanner};
    overflow: hidden;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    
    svg {
        margin: 40px 0;
    }
    
    &.visible {

    }
    
    &.hidden {
        display: none;
    }

    div {
        margin: 0;
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: max-content;
        
        h1 {
            letter-spacing: 1px;
            font-weight: 600;
        }
       
    }
    
    & .circles{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        
        & li{
            position: absolute;
            display: block;
            list-style: none;
            width: 20px;
            height: 20px;
            background: rgba(255, 255, 255, 0.2);
            animation: ${animate} 25s linear infinite;
            bottom: -150px;
            
        }
        
        & li:nth-child(1){
            left: 25%;
            width: 80px;
            height: 80px;
            animation-delay: 0s;
        }
        
        
        & li:nth-child(2){
            left: 10%;
            width: 30px;
            height: 30px;
            animation-delay: 1s;
            animation-duration: 3s;
        }
        
        & li:nth-child(3){
            left: 70%;
            width: 20px;
            height: 20px;
            animation-delay: 2s;
        }
        
        & li:nth-child(4){
            left: 5%;
            width: 60px;
            height: 60px;
            animation-delay: 0s;
            animation-duration: 4s;
        }
        
        & li:nth-child(5){
            left: 65%;
            width: 20px;
            height: 20px;
            animation-delay: 0s;
        }
        
        & li:nth-child(6){
            left: 75%;
            width: 110px;
            height: 110px;
            animation-delay: 2s;
        }
        
        & li:nth-child(7){
            left: 35%;
            width: 90px;
            height: 90px;
            animation-delay: 3s;
        }
        
        & li:nth-child(8){
            left: 50%;
            width: 25px;
            height: 25px;
            animation-delay: 2s;
            animation-duration: 7s;
        }
        
        & li:nth-child(9){
            left: 20%;
            width: 15px;
            height: 15px;
            animation-delay: 2s;
            animation-duration: 4s;
        }
        
        & li:nth-child(10){
            left: 85%;
            width: 150px;
            height: 150px;
            animation-delay: 0s;
            animation-duration: 6s;
        }
    }
    
      @media screen and (max-width: ${ theme.sizes.md }px) {
          width: 100%;
         
          svg {
            width: 90%;
            margin: 20px 0;
            
          }
     }
`

