const colors = {
    backgroundColor: "#f5f5f5",
    primaryLight: "#1e1e1e",
    blackLight: "#222",
    primary: "#00a135",
    primaryDark: "#0046CE",
    primaryBorder: "#aaa",
    bgBanner: "#008f39",
    colorBanner: "#fff",
    bgGray: '#f0f0f0',
    gray: '#999',
    black: '#000'
  } as const;
  
  export const theme = {
    colors,
    space: [0, 4, 8, 16, 24, 32, 64, 126, 256] as const,
    sizes: {xs: 480, md: 720, lg: 1080} as const,
    typography: {
      fontSize: '20px',
      fontSizeTitle: '38px',
        fontSizeSubtitle: '14px',
      color: colors.primary,
      bodyFontFamily: 'Neue',
      headerFontFamily: "Tiempos",
      thinFontFamily: 'Neue-light'
    } as const
  };
  
  export type Theme = typeof theme;