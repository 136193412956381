import * as React from "react";
import * as Styles from "./styles";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import {ReactElement} from "react";
import {Box} from "@mui/material";

const SPACE = 10;

interface SliderProps {
    items: any[],
}

/**
 * @param {node[]} items - items configuration
 */
const Slider = ({items}: SliderProps): ReactElement => {

    return (
        <>
            <Styles.Wrapper>
                {items.map((item, index) => (
                    <Box key={index}>{ item }</Box>
                ))}
            </Styles.Wrapper>
            <Styles.SliderWrapper>
                <Swiper
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                        el: '.swiper-pagination',
                        type: 'bullets',
                    }}
                    slidesPerView={"auto"}
                    spaceBetween={SPACE}
                    onSlideChange={() => items.map((item: any, index: number) => item.ref.current.handleClose())}
                    modules={[Pagination]}>
                    {items.map((item: React.ReactElement, index: number) => (
                        <SwiperSlide key={index}>{item}</SwiperSlide>
                    ))}
                </Swiper>
                <Box className={"swiper-pagination"}></Box>
            </Styles.SliderWrapper>
        </>
    );
}

export default Slider;