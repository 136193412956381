import styled from '@emotion/styled';
import {theme} from "../../theme";

export const Link = styled.a`
    text-decoration: none;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    cursor:pointer;
    gap: 5px;

    color: ${theme.colors.primary};
    
    &:hover, &:hover svg {
        color: ${theme.colors.primary};
    }

    & svg {
         color: ${theme.colors.primary};
    }

`;

export const Box = styled.div`
    display: 'flex';
    margin: 5px 0;
`;


// & svg {
//     transform: ${ props => (props.iconPosition === "top" && 
//                            props.icon === "NavigateNext") ? 'rotate(180deg)' : 'none' }
// }