import React from 'react';
import * as Styles from './styles';

interface LinkProps {
    type?: string;
    href?: string;
    className?: string | undefined;
    onClick: any,
    text: string,
    icon: any
}


const Link = (props: LinkProps) => {
    const {type = 'link', href, icon, onClick, text, className} = props;

    return (
        <Styles.Box className={className}>
            <Styles.Link type={type} href={href} onClick={onClick}>
                {text}
                {icon}
            </Styles.Link>
        </Styles.Box>
    );
}

export default Link;
