import styled from 'styled-components';
import { theme } from '../../theme'
import {Col, Container, Row} from "react-bootstrap";

export const Name = styled(Col)`
    font-size: ${theme.typography.fontSizeTitle}
`

export const IconContact = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    bottom: 3em;
    height: 50%;
    justify-content: space-between;

    & .text {
        margin-right: 10px;
    }
    
    & .name {
        color: ${theme.colors.primaryBorder};
        display: none;
        font-size: ${theme.typography.fontSizeSubtitle}
    }
    
    @media screen and (max-width: ${ theme.sizes.md }px) {
          position: initial;
          align-items: center;
          width: 100%;
          
          & .name {
            display: block;
            padding-bottom: 2em;
            font-weight: 700;
          }
          
           & .text {
            display: none;
          }
    }

`
export const IconRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media screen and (max-width: ${ theme.sizes.md }px) {
          flex-direction: row;
     }

    a {
        margin: 5px 5px 0;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
            color: ${theme.colors.primaryLight}
        }

        & svg:hover {
            color: white;
        }
    }

    a:hover {
        background: ${theme.colors.primary};
        border-radius: 50%;

        & svg {
            color: white;
        }
    }
`

export const RowContainer = styled(Row)`
    padding: 50px;
    width: 30em;
    
      @media screen and (max-width: ${ theme.sizes.md }px) {
          width: 100%;
          padding: 0px;
          margin: 40px 0 20px;
     }
`

export const RowInput = styled(Row)`
    width: 23em;
    position: absolute;
    right: 15%;
    
      @media screen and (max-width: ${ theme.sizes.md }px) {
          width: 100%;
          position: initial;
           margin: 40px 0;
     }

     @media screen and (min-width: ${ theme.sizes.lg }px) {
        width: 25em;
        margin: 50px 0;
   }
`

export const ContactContainer = styled(Container)`
    margin: 2.5rem;
    width: 85vw;
    border-right: 1px solid #aaa;
    position: relative;
    
    .input-container {
        position: relative;
    }
    
    .label {
        position: absolute;
        left: 10px;
        top: 11px;
        transition: all 0.2s;
        padding: 0 2px;
        z-index: 1;
        color: #b3b3b3;
    }
    
    .label::before {
        content: "";
        height: 5px;
        background: ${theme.colors.backgroundColor};
        position: absolute;
        left: 0px;
        top: 10px;
        width: 100%;
        z-index: -1;
    }
    
    .input-text:focus {
        border: 2px solid ${theme.colors.primary};
    }
    
    .input-text:focus + .label,
    .filled {
        top: -10px;
        color: ${theme.colors.primary};
        font-size: 14px;
    }
    
    .input-text::placeholder {
        font-size: 16px;
        opacity: 0;
        transition: all 0.3s;
    }
    .input-text:focus::placeholder {
        opacity: 1;
    }


    & .input-text {
        border: 1px solid ${theme.colors.primaryBorder};
        font-size: 16px;
        padding: 10px 15px;
        border-radius: 4px;
        width: 100%;
        margin-bottom: 1em;
        transition: all 0.3s;
        background: transparent;
        color: ${theme.colors.blackLight};
        outline: none;
        
        &.input-error {
            border-color: #ff0000;
        }
    }
    
    & .button-send {
       width: 30%;
       float: right;
       cursor: pointer;
       
       & img {
        width: 30px;
        }
    }
    
    & .button-send:hover {
    
     & img {
        transform: translateX(10px);
        transition: transform 0.4s ease-out;
      }
    }

    & .text-area {
        resize: none;
        rows: 5;
      }
      
    @media screen and (max-width: ${ theme.sizes.md }px) {
          margin: 20px;
          border-right: 0px;
    }
`

export const Contact = styled.div`
   display: flex;
   
   @media screen and (max-width: ${ theme.sizes.md }px) {
       margin: 0px;
       display: block;
    }
`
