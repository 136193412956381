import styled from 'styled-components';
import { theme } from '../../theme'


export const Header = styled.header`
    position: fixed;
    top: 50%;
    left: 2em;
    width: 100vh;
    z-index: 100;
    -webkit-transform: translate3d(-50.5%,-50%,0) rotate(-90deg);
    transform: translate3d(-50.5%,-50%,0) rotate(-90deg);
    background: #f5f5f5;
    
    & .header_menu {
        padding: 0;
        list-style: none;
        border-bottom: 1px solid #aaa;
        font-size: .975rem;
        margin: 0 2.5rem;
        color: ${theme.colors.primaryLight};
        background: #f5f5f5;

        & .header_menu_item {
            display: inline-block;
            position: relative;
            padding: 1.25rem 0;
            margin: 0 1.25rem;
            line-height: 1.2;
            
            & button {
               border: none;
               margin-right: 5px;
               cursor: pointer;  
               background: transparent;
            }
            
            & button.active {
                 border-bottom: 1px solid ${theme.colors.primaryBorder};
            }
            
            &.active a {
               color: ${theme.colors.primary};
            }
            
            & a {
                text-decoration: none;
                color: ${theme.colors.primaryLight};
               
            }
        }
    }

    & .header_logo {
        position: absolute;
        top: 60%;
        right: 2rem;
        letter-spacing: 1px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 16.25rem;
        height: 2.5rem;
        display: block;
        text-decoration: none;
        color: ${theme.colors.primaryLight};
        background: transparent;
        border: 0;
        cursor: pointer;
        font-size: 18px;
    }
    
    &.visible {
      left: 2em;
      transition: left 0.4s ease-out;
    }
    
    &.hidden {
      left: -60px;
      transition: left 1s ease-out;
    }
`

export const HeaderMobile = styled.header`
    position: fixed;
    width: 100%;
    z-index: 100;
   
    background: #f5f5f5;
    
    & .nav_menu {
        padding: 1rem;
        list-style: none;
        border-bottom: 4px solid ${theme.colors.primaryLight};
        border-top: 1px solid ${theme.colors.primaryLight};
        margin-top: 1rem;
        font-size: .875rem;
        color: ${theme.colors.primaryLight};
        background: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & button.button {
            background: transparent;
            border: 0;
            padding: 10px 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 30px;
            height: 30px;
            transition: all .2s ease-in-out;
            cursor: pointer;
            
            & div {
               border-bottom: 1px solid ${theme.colors.primaryLight};
               width: 30px;
               margin-bottom: 5px;
            }
            
            &.open{
                div{
                    &:nth-child(1) {
                        transform: translate(0px, 5px) rotate(-45deg) scalex(0.9);
                        transition: transform 0.2s ease-out;
                        margin: 0;
                    }
                    &:nth-child(2){
                        transform: translate(0px, 4px) rotate(45deg) scalex(0.9);  
                        transition: transform 0.2s ease-out;             
                    }
                }
            }
            
            &.close{
                div{
                    &:nth-child(1) {
                        transform: translate(0px, 0px) rotate(0deg) scalex(1);
                        transition: transform 0.2s ease-out;
                    }
                    &:nth-child(2){
                        transform: translate(0px, 0px) rotate(0deg) scalex(1);  
                        transition: transform 0.2s ease-out;             
                    }
                }
            }
        }
    }
    
    & .header_menu {
        padding: 0;
        list-style: none;
        border-bottom: 1px solid #aaa;
        font-size: 2rem;
        font-weight: 600;
        margin: 0;
        color: ${theme.colors.primaryLight};
        background: #f5f5f5;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        height: 0;
        
        & .header_menu_item {
        
            &:first-child {
              border-top: 1px solid #aaa;
            }
            padding: .5rem 2rem;
            line-height: 1.2;
            width: 100%;
            border-bottom: 1px solid #aaa;
            
             &.active a {
               color: ${theme.colors.primary};
            }
            
            & a {
                text-decoration: none;
                color: ${theme.colors.primaryLight};
               
            }
            
            &_language {
                position: fixed;
                bottom: 5%;
                opacity: 0;
                
                &.disabled {
                   display: none;
                }
                
                &.show {
                    animation-name: show;
                    animation-delay: 1050ms;
                    animation-fill-mode: forwards;   
                }
                
                & button {
                   border: none;
                   margin-right: 5px;
                   cursor: pointer;
                   background: transparent;
                   font-size: 1rem;
                   color: ${theme.colors.primaryLight};
                }
            
                & button.active {
                    color: ${theme.colors.primaryLight};
                    font-weight: 600;
                }
            }
        }
        
        &.open {
            animation-name: openMenu;
            animation-duration: 2s;
            animation-fill-mode: forwards;       
          }
          
        &.close {
            height: 0;
           animation-fill-mode: forwards;   
            animation-name: closeMenu;
          animation-duration: 1s;     
        }
    }

    & .header_logo {
        text-decoration: none;
        color: ${theme.colors.primaryLight};
        -webkit-text-stroke: 1px #1e1e1e;
        font-family: 'Neue-light';
        letter-spacing: 1px;
        background: transparent;
        border: 0;
        cursor: pointer;
    }
    
    
    @keyframes openMenu {
      from {height: 0}
      to {height: 100vh;}
    }
    
     @keyframes closeMenu {
      from {height: 100vh}
      to {height: 0}
    }
    
    @keyframes show {
      from {opacity: 0;}
      to {opacity: 1;}
    }
    
`